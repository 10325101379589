import {FC, useEffect, useRef} from 'react';
import ReactMarkdown from 'react-markdown';
import {ResponseInterface} from "./response-interface";
import hljs from 'highlight.js';
import parse from 'html-react-parser';
import './PromptResponseList.css';

interface PromptResponseListProps {
  responseList: ResponseInterface[];
}

const PromptResponseList: FC<PromptResponseListProps> = ({ responseList }) => {
  const responseListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    hljs.highlightAll();
  })

  useEffect(() => {
    hljs.highlightAll();
  }, [responseList]);

  const stringHasHTML = (str:any) => {
    const regex = /<\/?[a-z][\s\S]*>/i;
    return regex.test(str);
  }
  

  return (
    <div className="prompt-response-list" ref={responseListRef}>
      {responseList.map((responseData) => (
        <div className={"response-container " + (responseData.selfFlag ? 'my-question' : 'chatgpt-response')} key={responseData.id}>
          <div className="chat-icon">
            {responseData.selfFlag ? <i className="bi bi-patch-question-fill me-icon"></i> : <i className="bi bi-chat-right-quote-fill chatgpt-icon"></i>}
          </div>
          {/*
          <img className="avatar-image" src={responseData.selfFlag ? MyImg : ChatGptImg} alt="avatar"/>
          */}
          <div className={(responseData.error ? 'error-response ' : '') + "prompt-content"} id={responseData.id}>
            { responseData.images && responseData.images.map((imageUrl, index) => (
              <img key={index} src={imageUrl} className="ai-image" alt="generated ai" />
            ))}
            { (responseData.response && stringHasHTML(responseData.response)) ? parse(responseData.response)
             : responseData.response ?
              <ReactMarkdown
                children={responseData.response ?? ''}
                components={{
                  code({className, children}) {
                    return(
                      <code className={className}>
                        {children}
                      </code>
                    )
                  }
                }}
              /> : ""
            }
          </div>
        </div>
      ))}
    </div>
  );
};

export default PromptResponseList;
