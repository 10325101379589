import { useState } from "react";
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import { Input } from './Input'
import blur from '../../assets/blur.webp'
import { createUser } from "../../graphql/mutations";

export const SignUp = () => {
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("")
  const navigate = useNavigate();
  const [storeUserId, setStoreUserId] = useState("");

  const handleValSignUpButton = async () => {
    if(isSendEmail) {
      try {
        // NOTE(riku0325): メール認証を行う
        await Auth.confirmSignUp(email, code);
        await API.graphql(
          graphqlOperation(
            createUser, {
              input: {
                userId: storeUserId,
                status: 'ADMIN',
                count: 0,
                adminUserId: storeUserId,
                email,
                password
              }
            }
          )
        )
        navigate("/");
      } catch (error) {
        alert(error);
      }
    } else {
      // NOTE(riku0325): emailを送る
      try {
        const user = await Auth.signUp({
          username: email,
          password,
        });
        setStoreUserId(user.userSub)
        setIsSendEmail(true);
      } catch (error) {
        // @ts-ignore
        if(error.message === "An account with the given email already exists.") {
          alert(error)
        } else {
          await Auth.resendSignUp(email)
          setIsSendEmail(true);
        }
      }
    }
  }

  return(
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        marginTop: "5rem",
      }}
    >
      <div className="bg-blob">
        <img alt="" src={blur} />
      </div>

      <h1 className="app-title image-bg">Chact</h1>

      <h1 className="auth-title">Admin Sign Up</h1>

      <div className="auth-container">

        <Input
          label="Email Address"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />

        <Input
          label="Password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />

        <div
          style={{
            fontSize: '0.7rem'
          }}
        >
          <p className="mb-0 text-faded">Password must contain:</p>
          <ul className="text-faded">
            <li>An uppercase letter</li>
            <li>A lowercase letter</li>
            <li>A number</li>
            <li>More than 8 characters</li>
          </ul>
        </div>

        {isSendEmail && (
          <>
            <Input
              label="Verification Code"
              value={code}
              onChange={(e: any) => setCode(e.target.value)}
            />
          </>
        )}

        {/*
        <div
          style={{
            display: isSendEmail ? "flex" : "none",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div>valifation code</div>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button
            type="button"
            className="btn theme-button large main-color"
            onClick={handleValSignUpButton}
          >
            {isSendEmail ? "Validate" : "Sign Up"}<i className="bi bi-arrow-right-circle ms-2"></i>
          </button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <a href="/" className="link-text">Login</a>
        </div>
      </div>
    </div>
  )
}
