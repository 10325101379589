import { BrowserRouter, Route, Routes } from "react-router-dom"
import { SignUp } from "./SignUp"
import ChatGPT from "./ChatGPT"
import ShareChatGPT from "./ShareChatGPT"
import { Login } from "./Login"
import { AuthProvider } from "./AuthProvider"
import { ForgetPassword } from "./ForgetPassword"
import { Amplify } from "aws-amplify"
import awsmobile from "../../aws-exports"
import { AdminSetting } from "./AdminSetting"
import { ShareProvider } from "./ShareProvider"
import { Page404 } from "./Page404"

Amplify.configure(awsmobile)

export const App = () => {
  return(
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Login />} />
        <Route path={`/sign-up`} element={<SignUp />} />
        <Route path={`/forget-password`} element={<ForgetPassword />} />
        <Route path={`/auth/admin`} element={<AuthProvider />} >
          <Route path={`/auth/admin/setting`} element={<AdminSetting />} />
          <Route path={`/auth/admin/chatgpt`} element={<ChatGPT />} />
        </Route>
        <Route path={`/share/user/:userId`} element={<ShareProvider />} >
          <Route path={`/share/user/:userId`} element={<ShareChatGPT />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  )
}
