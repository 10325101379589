import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom"

export const AuthProvider = () => {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsLogin(true);
      })
      .catch(() => {
        navigate("/");
      })
  }, [navigate]);

  return(
    <>
      {
        isLogin ? <Outlet /> : <div />
      }
    </>
  )
}
