import { Button, IconButton, List, ListItem, ListItemText, TextField, Typography } from "@mui/material"
import { API, Auth, graphqlOperation } from "aws-amplify"
import { useEffect, useState } from "react"
import { v4 as uuidv4 } from 'uuid';
import { createUser, deleteUser } from "../../graphql/mutations"
import { usersByAdminUserId, usersByEmail } from "../../graphql/queries";
import { User } from "../../API";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";

export const AdminSetting = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [storeMembers, setStoreMembers] = useState<User[]>([])
  const [storeMember, setStoreMember] = useState<User | null>(null)
  const [selectLanguageVisible, setSelectLanguageVisible] = useState(false)
  const [country, setCountry] = useState<"japan" | "english">("japan");
  const navigate = useNavigate();

  useEffect(() => {
    componentDidMount()
  }, [])

  const componentDidMount = async () => {
    const user = await Auth.currentAuthenticatedUser();
    // adminUserIdのリストを取得
    const data = await API.graphql(
      graphqlOperation(
        usersByAdminUserId, {
          adminUserId: user.attributes.sub
        }
      )
    ) as {
      data: {
        usersByAdminUserId: {
          items: User[]
        }
      }
    }

    setStoreMember(data.data.usersByAdminUserId.items.filter(item => item.userId === user.attributes.sub)[0])
    setStoreMembers(data.data.usersByAdminUserId.items.filter(item => item.userId !== user.attributes.sub))
  }

  const inviteUser = async () => {
    if(email === "" || password === "") {
      alert("入力してください")
      return
    }

    const data = await API.graphql(
      graphqlOperation(
        usersByEmail, {
          email
        }
      )
    ) as {
      data: {
        usersByEmail: {
          items: User[]
        }
      }
    }

    if (data.data.usersByEmail.items.length > 0) {
      alert("既に登録されています")
      return
    }

    const user = await Auth.currentAuthenticatedUser();
    await API.graphql(
      graphqlOperation(
        createUser, {
          input: {
            userId: uuidv4(),
            status: 'SHARE',
            count: 0,
            adminUserId: user.attributes.sub,
            email,
            password
          }
        }
      )
    )

    alert("ユーザーを登録しました。対象ユーザーにパスワード等を送信してください。")

    setEmail("")
    setPassword("")

    componentDidMount()
  }

  const handleOnClickLogout = async () => {
    await Auth.signOut();
    navigate('/');
  }

  const handleOnClickDelete = async (user: User) => {
    // ユーザー削除
    await API.graphql(
      graphqlOperation(
        deleteUser, {
          input: {
            userId: user.userId
          }
        }
      )
    )

    componentDidMount()
  }

  return(
    <div className="bg-white" style={{ minHeight: "100vh" }}>
      <nav className="navbar navbar-dark bg-white d-flex">
        <a className="navbar-brand" href="/"><span className="app-title image-bg small">Chact</span></a>

        <div
          className="btn ml-auto mr-3"
        >
          {country === "japan" ? `現在のグループ全体の使用回数: ${storeMember?.count ?? ""}` : `Current user group count: ${storeMember?.count ?? ""}`}
        </div>

        <button
          type="button"
          className="btn mr-3"
          style={{
            border: "1px solid #000000",
          }}
          onClick={() => navigate('/auth/admin/chatgpt')}
        >
          {country === "japan" ? "chact コンソール" : "stabi console"}
        </button>

        <button
          type="button"
          className="btn theme-button main-color mr-3"
          onClick={() => setSelectLanguageVisible(true)}
        >
          <span><i className="bi bi-globe me-2"></i></span>{country === "japan" ? "日本語" : "English"}
        </button>

        <button
          type="button"
          className="btn btn-secondary theme-button"
          onClick={handleOnClickLogout}
        >
          {country === "japan" ? "ログアウト" : "Logout"}
        </button>
      </nav>
      {selectLanguageVisible && (
        <div className="select-language-wrapper" style={{ zIndex: 9999 }}>
          <div className="select-language card">

            <div className="card-header select-language-bg-fill">
              <p className="mb-0 text-center text-faded">
                {country === "japan" ? "言語を選択してください" : "Select Language"}
              </p>
            </div>

            <div className="card-body p-4">
              <div className="d-flex flex-column text-center">

                <div
                  className="hover-text my-1 p-2"
                  onClick={() => { setCountry("japan"); setSelectLanguageVisible(false); }}
                >
                  <span>日本語</span>
                </div>

                <div
                  className="hover-text my-1 p-2"
                  onClick={() => { setCountry("english"); setSelectLanguageVisible(false); }}
                >
                  <span>English</span>
                </div>

              </div>
            </div>

            <div className="card-footer select-language-bg-fill d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setSelectLanguageVisible(false)}
              >
                {country === "japan" ? "閉じる" : "Close"}
              </button>
            </div>

          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5rem",
        }}
      >
        <div
          style={{
            maxWidth: "500px",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {
              country === "japan" ? "招待したいユーザー" : "Invite User"
            }
          </div>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            variant="outlined"
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            variant="outlined"
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          />
          <Button
            variant="contained"
            style={{
              width: "100%",
              marginTop: "20px",
            }}
            onClick={inviteUser}
          >
            {
              country === "japan" ? "招待" : "Invite"
            }
          </Button>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {
              country === "japan" ? "メンバー" : "Members"
            }
          </Typography>
          <List>
            {
              storeMembers.map((user) => {
                return (
                  <ListItem
                    key={user.userId}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleOnClickDelete(user)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={user.email}
                    />
                  </ListItem>
                )
              })
            }
            {
              storeMembers.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {
                    country === "japan" ? "メンバーがいません" : "No members"
                  }
                </div>
              )
            }
          </List>
        </div>
      </div>
    </div>
  )
}