import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from './Input'
import blur from '../../assets/blur.webp'

export const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("")
  const [isSendEmail, setIsSendEmail] = useState(false);
  const navigate = useNavigate();

  const handleForgetPassword = async () => {
    if(isSendEmail) {
      await Auth.forgotPasswordSubmit(email, code, password)
      navigate("/");
    } else {
      await Auth.forgotPassword(email);
      setIsSendEmail(true);
    }
  }

  return(
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        marginTop: "5rem",
      }}
    >
      <div className="bg-blob">
        <img alt="" src={blur} />
      </div>

      <h1 className="app-title image-bg">Chact</h1>

      <h1 className="auth-title">Forgot Password</h1>
      <div className="auth-container">

        <Input
          label="Email Address"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />

        {isSendEmail && (
          <>
            <Input
              label="New Password"
              type="password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />

            <div
              style={{
                fontSize: '0.7rem'
              }}
            >
              <p className="mb-0 text-faded">Password must contain:</p>
              <ul className="text-faded">
                <li>An uppercase letter</li>
                <li>A lowercase letter</li>
                <li>A number</li>
                <li>More than 8 characters</li>
              </ul>
            </div>

            <Input
              label="Verification Code"
              value={code}
              onChange={(e: any) => setCode(e.target.value)}
            />
          </>
        )}

        {/*
        <div
          style={{
            display: isSendEmail ? "flex" : "none",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div>new password</div>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div
            style={{
              fontSize: "8px",
            }}
          >
            uppercase letter, lowercase letter, number, more than 8 characters
          </div>
        </div>

        <div
          style={{
            display: isSendEmail ? "flex" : "none",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div>valifation code</div>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button
            type="button"
            className="btn theme-button large main-color"
            onClick={handleForgetPassword}
          >
            {isSendEmail ? "Validate" : "Send"}<i className="bi bi-arrow-right-circle ms-2"></i>
          </button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <a href="/" className="link-text">Login</a>
        </div>
      </div>
    </div>
  )
}
