import { useNavigate } from 'react-router-dom';
import blur from '../../assets/blur.webp'

export const Page404 = () => {
  const navigate = useNavigate();

  return(
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        marginTop: "5rem",
      }}
    >
      <div className="bg-blob">
        <img
          alt=""
          src={blur}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="app-title-container">
        <h1 className="app-title image-bg">Stadi</h1>
      </div>

      <hr />
      <br/>
      <br/>

      <h1 className="auth-title">Not Found</h1>
    </div>
  )
}