import {useEffect, useState} from 'react';
import PromptInput from "../PromptInput/PromptInput";
import './App.css';
import {ResponseInterface} from "../PromptResponseList/response-interface";
import PromptResponseList from "../PromptResponseList/PromptResponseList";
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useNavigate, useParams } from 'react-router-dom';
import { Configuration, OpenAIApi } from 'openai';
import { User } from '../../API';
import { getUser, usersByAdminUserId } from '../../graphql/queries';
import { updateUser } from '../../graphql/mutations';

type ModelValueType = 'kentiku' | 'software' | 'gpt4' | 'consult' | 'planner';


const App = () => {

  const [selectLanguageVisible, setSelectLanguageVisible] = useState(false)
  const [responseList, setResponseList] = useState<ResponseInterface[]>([]);
  const [prompt, setPrompt] = useState<string>('');
  const [prompt2, setPrompt2] = useState<string>('');
  const [promptToRetry, setPromptToRetry] = useState<string | null>(null);
  const [uniqueIdToRetry, setUniqueIdToRetry] = useState<string | null>(null);
  const [modelValue, setModelValue] = useState<ModelValueType>('gpt4');
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState<"japan" | "english">("japan");
  const [storeAdminMember, setStoreAdminMember] = useState<User | null>(null)
  let { userId } = useParams();
  let loadInterval: number | undefined;
  const navigate = useNavigate();

  useEffect(() => {
    componentDidMount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const componentDidMount = async () => {
    if(!userId) {
      return
    }
    // adminUserIdのリストを取得
    const data = await API.graphql(
      graphqlOperation(
        getUser, {
          userId: userId
        }
      )
    ) as {
      data: {
        getUser: User
      }
    }

    const adminData = await API.graphql(
      graphqlOperation(
        usersByAdminUserId, {
          adminUserId: data.data.getUser.adminUserId
        }
      )
    ) as {
      data: {
        usersByAdminUserId: {
          items: User[]
        }
      }
    }

    setStoreAdminMember(adminData.data.usersByAdminUserId.items.filter((user) => user.userId !== userId)[0])
  }

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomNumber = Math.random();
    const hexadecimalString = randomNumber.toString(16);

    return `id-${timestamp}-${hexadecimalString}`;
  }

  const htmlToText = (html: string) => {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent;
  }


  const addResponse = (selfFlag: boolean, response?: string) => {
    const uid = generateUniqueId()
    setResponseList(prevResponses => [
      ...prevResponses,
      {
        id: uid,
        response,
        selfFlag
      },
    ]);
    return uid;
  }

  const updateResponse = (uid: string, updatedObject: Record<string, unknown>) => {
    setResponseList(prevResponses => {
      const updatedList = [...prevResponses]
      const index = prevResponses.findIndex((response) => response.id === uid);
      if (index > -1) {
        updatedList[index] = {
          ...updatedList[index],
          ...updatedObject
        }
      }
      return updatedList;
    });
  }

  const regenerateResponse = async () => {
    await getGPTResult(promptToRetry, uniqueIdToRetry);
  }

  const getGPTResult = async (_promptToRetry?: string | null, _uniqueIdToRetry?: string | null) => {
    let storePrompt = ""

    if(modelValue === "software") {
      storePrompt = country === "japan" ?
        "ソフトウェア: " + prompt2 + "\n エラー: " + prompt
      :
        "Software: " + prompt2 + " Error: " + prompt
    } else {
      storePrompt = prompt
    }

    // Get the prompt input
    const _prompt = _promptToRetry ?? htmlToText(storePrompt);

    // If a response is already being generated or the prompt is empty, return
    if (isLoading || !_prompt) {
      return;
    }

    setIsLoading(true);

    // Clear the prompt input
    setPrompt('');

    let uniqueId: string;
    if (_uniqueIdToRetry) {
      uniqueId = _uniqueIdToRetry;
    } else {
      // Add the self prompt to the response list
      addResponse(true, _prompt);
      uniqueId = addResponse(false);
      // await delay(50);
      // addLoader(uniqueId);
    }

    try {
      const endpoints = {
        "software": 'https://api.openai.com/v1/chat/completions',
        "kentiku": 'https://api.openai.com/v1/completions',
        "consult": 'https://h9czc2kr6b.execute-api.ap-northeast-1.amazonaws.com/prod/consult',
        "planner" : 'https://escaf0uxfh.execute-api.ap-northeast-1.amazonaws.com/prod/stabirity-func',
        "gpt4" : 'https://api.openai.com/v1/chat/completions'
      }
      const url = endpoints[modelValue];

      const body = modelValue === "software" ? {
        messages: [
          {
            role: 'system',
            content: country === "japan" ? "このエラーの解消方法を日本語で回答してください。" : "Please answer in English how to resolve this error.",
          },
          ...responseList.map((response, index) => {
            if(index % 2 === 0) {
              return {
                role: 'user',
                content: response.response
              }
            } else {
              return {
                role: 'assistant',
                content: response.response
              }
            }
          }),
          { role: 'user', content: prompt },
        ],
        temperature: 0.5,
        model: 'gpt-3.5-turbo',
        stream: true,
        max_tokens: 1000
      } : modelValue === "kentiku" ? {
        prompt: prompt,
        model: "davinci:ft-personal-2023-03-19-09-05-01",
        "max_tokens": 400
      } : modelValue === 'consult' ? {
        "body": {
            "user_input": prompt,
            "language" : country　=== 'japan' ? '日本語' : 'English',
        }
      } : modelValue === 'planner' ? {
        "httpMethod": "POST",
        "body": {
            "user_input": prompt,
            "messages": [
              ...responseList.map((response, index) => {
                if (index % 2 === 0) {
                  return {
                    role: 'user',
                    content: response.response
                  };
                } else {
                  return {
                    role: 'assistant',
                    content: response.response
                  };
                }
              }),
            ],
            "language" : country,
        }
      } : {
        messages: [
          {
            role: 'system',
            content: country === "japan" ? "日本の法律を踏まえて日本語で回答してください。" : "Please answer in Japanese based on Japanese law.",
          },
          ...responseList.map((response, index) => {
            if(index % 2 === 0) {
              return {
                role: 'user',
                content: response.response
              }
            } else {
              return {
                role: 'assistant',
                content: response.response
              }
            }
          }),
          { role: 'user', content: prompt },
        ],
        temperature: 0.5,
        model: 'gpt-4',
        stream: true,
        max_tokens: 1000
      }


      if(modelValue === "software") {
        const completion = await fetch(
          url,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            method: 'POST',
            body: JSON.stringify(body)
          }
        )

        let storeResultText = ""

        const reader = completion.body?.getReader()

        if (completion.status !== 200 || !reader) {
          return 'error'
        }

        const decoder = new TextDecoder('utf-8')
        try {
          const read = async (): Promise<any> => {
            const { done, value } = await reader.read()

            if (done) return reader.releaseLock()

            const chunk = decoder.decode(value, { stream: true })

            const jsons = chunk
              .split('data:')
              .map(data => {
                const trimData = data.trim()
                if (trimData === '') return undefined
                if (trimData === '[DONE]') return undefined
                return JSON.parse(data.trim())
              })
              .filter(data => data)

            const responseData = jsons
              .map(data =>
                data.choices
                  .map(
                    (choice: { delta: { content: string } | null }) =>
                      choice.delta?.content
                  )
                  .join()
              )
              .join()

            const replaceData = responseData.replace(/,/g, '')
            storeResultText += replaceData
            updateResponse(uniqueId, {
              response: storeResultText.trim(),
            });

            const scrollerInner = document.getElementById('chatMessageId')
            if (scrollerInner) {
              scrollerInner.scrollIntoView(false)
            }

            return read()
          }
          await read()
        } catch (e) {
          console.error(e)
        }

        reader.releaseLock()
      } else if(modelValue === "consult") {
        const completion = await fetch(
          url,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            method: 'POST',
            body: JSON.stringify(body)
          }
        )

        let storeResultText = ""

        const reader = completion.body?.getReader()

        if (completion.status !== 200 || !reader) {
          return 'error'
        }

        const decoder = new TextDecoder('utf-8')
        try {
          const read = async (): Promise<any> => {
            const { done, value } = await reader.read()

            if (done) return reader.releaseLock()

            const chunk = decoder.decode(value, { stream: true })

            const jsons = chunk
              .split('data:')
              .map(data => {
                const trimData = data.trim()
                if (trimData === '') return undefined
                if (trimData === '[DONE]') return undefined
                return JSON.parse(data.trim())
              })
              .filter(data => data)

            const responseData = jsons[0].body

            const replaceData = responseData.replace(/,/g, '')
            storeResultText += replaceData
            updateResponse(uniqueId, {
              response: storeResultText.trim(),
            });

            const scrollerInner = document.getElementById('chatMessageId')
            if (scrollerInner) {
              scrollerInner.scrollIntoView(false)
            }

            return read()
          }
          await read()
        } catch (e) {
          console.error(e)
        }

        reader.releaseLock()
      }  else if(modelValue === "planner") {
        const completion = await fetch(
          url,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            method: 'POST',
            body: JSON.stringify(body)
          }
        )        

        let storeResultText = ""

        const reader = completion.body?.getReader()

        if (completion.status !== 200 || !reader) {
          return 'error'
        }

        const decoder = new TextDecoder('utf-8')
        try {
          const read = async (): Promise<any> => {
            const { done, value } = await reader.read()

            if (done) return reader.releaseLock()

            const chunk = decoder.decode(value, { stream: true })

            const jsons = chunk
              .split('data:')
              .map(data => {
                const trimData = data.trim()
                if (trimData === '') return undefined
                if (trimData === '[DONE]') return undefined
                return JSON.parse(data.trim())
              })
              .filter(data => data)

            const responseData = jsons[0].body.content

            if(jsons[0].body.type === "text"){
              const replaceData = responseData.replace(/,/g, '')
              storeResultText += replaceData
              updateResponse(uniqueId, {
                response: storeResultText.trim(),
              });
  
            } else if(jsons[0].body.type === "image") {
              updateResponse(uniqueId, {
                images: responseData,
              });
            }

            const scrollerInner = document.getElementById('chatMessageId')
            if (scrollerInner) {
              scrollerInner.scrollIntoView(false)
            }
            return read()
          }
          await read()
        } catch (e) {
          console.error(e)
        }

        reader.releaseLock()
      } else {
        const completion = await fetch(
          url,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            method: 'POST',
            body: JSON.stringify(body)
          }
        )

        let storeResultText = ""

        const reader = completion.body?.getReader()

        if (completion.status !== 200 || !reader) {
          return 'error'
        }

        const decoder = new TextDecoder('utf-8')
        try {
          const read = async (): Promise<any> => {
            const { done, value } = await reader.read()

            if (done) return reader.releaseLock()

            const chunk = decoder.decode(value, { stream: true })

            const jsons = chunk
              .split('data:')
              .map(data => {
                const trimData = data.trim()
                if (trimData === '') return undefined
                if (trimData === '[DONE]') return undefined
                return JSON.parse(data.trim())
              })
              .filter(data => data)

            const responseData = jsons
              .map(data =>
                data.choices
                  .map(
                    (choice: { delta: { content: string } | null }) =>
                      choice.delta?.content
                  )
                  .join()
              )
              .join()

            const replaceData = responseData.replace(/,/g, '')
            storeResultText += replaceData
            updateResponse(uniqueId, {
              response: storeResultText.trim(),
            });

            const scrollerInner = document.getElementById('chatMessageId')
            if (scrollerInner) {
              scrollerInner.scrollIntoView(false)
            }

            return read()
          }
          await read()
        } catch (e) {
          console.error(e)
        }

        reader.releaseLock()
      }

      // 画像の検索回数を増やす
      if(storeAdminMember) {
        const nowCount = storeAdminMember.count as number
        const data = await API.graphql(
          graphqlOperation(
            updateUser, {
              input: {
                ...storeAdminMember,
                count: nowCount + 1
              }
            }
          )
        ) as {
          data: {
            updateUser: User
          }
        }

        setStoreAdminMember(data.data.updateUser)
      }

      setPromptToRetry(null);
      setUniqueIdToRetry(null);
    } catch (err) {
      setPromptToRetry(_prompt);
      setUniqueIdToRetry(uniqueId);
      updateResponse(uniqueId, {
        // @ts-ignore
        response: `Error: ${err.message}`,
        error: true
      });
    } finally {
      // Clear the loader interval
      clearInterval(loadInterval);
      setIsLoading(false);
    }
  }

  const handleOnClickLogout = async () => {
    await Auth.signOut();
    navigate('/');
  }

  return (
    <div className="App">
      <div className="toolbar-top">
        {/*
        <div>
          <select
            name="type"
            id="type"
            form="main"
            className="form-select"
            value={country}
            onChange={(e) => setCountry(e.target.value as "japan" | "english")}
          >
            <option value="japan">日本語</option>
            <option value="english">English</option>
          </select>
        </div>
        */}

        <div>
          <button
            type="button"
            className="btn theme-button main-color"
            onClick={() => setSelectLanguageVisible(true)}
          >
            <span><i className="bi bi-globe me-2"></i></span>{country === "japan" ? "日本語" : "English"}
          </button>
        </div>

        <div
          className="btn ml-auto mr-3"
          style={{
            color: "#fff",
          }}
        >
          {country === "japan" ? `現在のグループ全体の使用回数: ${storeAdminMember?.count ?? ""}` : `Current user group count: ${storeAdminMember?.count ?? ""}`}
        </div>

        <div>
          <button
            type="button"
            className="btn btn-dark theme-button"
            onClick={handleOnClickLogout}
          >
            {country === "japan" ? "ログアウト" : "Logout"}
          </button>
        </div>
      </div>


      {selectLanguageVisible && (
        <div className="select-language-wrapper">
          <div className="select-language card">

            <div className="card-header select-language-bg-fill">
              <p className="mb-0 text-center text-faded">
                {country === "japan" ? "言語を選択してください" : "Select Language"}
              </p>
            </div>

            <div className="card-body p-4">
              <div className="d-flex flex-column text-center">

                <div
                  className="hover-text my-1 p-2"
                  onClick={(e) => { setCountry("japan"); setSelectLanguageVisible(false); }}
                >
                  <span>日本語</span>
                </div>

                <div
                  className="hover-text my-1 p-2"
                  onClick={(e) => { setCountry("english"); setSelectLanguageVisible(false); }}
                >
                  <span>English</span>
                </div>

              </div>
            </div>

            <div className="card-footer select-language-bg-fill d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => setSelectLanguageVisible(false)}
              >
                {country === "japan" ? "閉じる" : "Close"}
              </button>
            </div>

          </div>
        </div>
      )}


      <div
        id="response-list"
        style={{
          marginTop: "60px",
        }}
      >
        <PromptResponseList responseList={responseList} key="response-list"/>
      </div>
      { uniqueIdToRetry &&
        (<div id="regenerate-button-container">
          <button id="regenerate-response-button" className={isLoading ? 'loading' : ''} onClick={() => regenerateResponse()}>
            Regenerate Response
          </button>
        </div>
        )
      }
      <div id="model-select-container">
        <label htmlFor="model-select">
          {
            country === "japan" ? "モデル選択:" : "Select model:"
          }
        </label>
        <select id="model-select" value={modelValue} onChange={(event) => setModelValue(event.target.value as ModelValueType)}>
          <option value="gpt4">
            {
              country === "japan" ? "建設業務などに関する質問ができます" : "You can ask questions about construction work, etc."
            }
          </option>
          {/* <option value="kentiku">
            {
              country === "japan" ? "建築関連の検索" : "Building related search"
            }
          </option> */}
          <option value="software">
            {
              country === "japan" ? "ソフトウェアのエラー解消方法を知ることができます" : "Know how to resolve software errors"
            }
          </option>
          <option value="consult">
            {
              country === "japan" ? "建築基準法関連の質問に出典を引用しながら回答します" : "You can ask questions about construction law."
            }
          </option>
          <option value="planner">
            {
              country === "japan" ? "AIプランナーに画像を作成してもらいます" : "AI planner creates images."
            }
          </option>
        </select>
      </div>

        {
          modelValue === "software" &&
            <div className="input-container">
              <div className="input-hint text-faded">
                {
                  country === "japan" ? "ソフトウェアの名前を書いてください（例：AutoCAD）: " : "Write the name of the software (e.g. AutoCAD): "
                }
              </div>
              <PromptInput
                prompt={prompt2}
                onSubmit={() => {}}
                key="prompt-input"
                updatePrompt={(prompt) => setPrompt2(prompt)}
              />
            </div>
        }

        <div className="input-container">
          <div className="input-hint text-faded">
            {
              (() => {
                if (modelValue === "software") {
                  return country === "japan"
                    ? "エラー内容をこちらにコピー＆ペーストしてください: "
                    : "Please copy and paste the error message here: ";
                } else if (modelValue === "consult") {
                  return country === "japan"
                    ? "建設基準法に関する質問ができます: "
                    : "You can ask questions about construction law.: ";
                } else if (modelValue === "planner") {
                  return country === "japan"
                    ? "作成したい画像のイメージを入力してください: "
                    : "Please enter the concept of the image you want to create here.: ";
                } else {
                  return country === "japan"
                    ? "建設業務などに関する質問ができます: "
                    : "You can ask questions about construction work, etc.: ";
                }
              })()
            }
          </div>

          <div className="prompt-input-line">
            <PromptInput
              prompt={prompt}
              onSubmit={() => {}}
              key="prompt-input"
              updatePrompt={(prompt) => setPrompt(prompt)}
            />

            {isLoading ? (
              <div className="spinner-grow loading-spinner" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <button
                type="button"
                className={`btn theme-button main-color ${isLoading ? 'loading' : ''}`}
                onClick={() => getGPTResult()}
                disabled={modelValue === 'software' ? (!prompt2 || !prompt) : (!prompt)}
              >
                <i className="bi bi-send-fill me-2"></i>{country === "japan" ? '送信' : 'Send'}
              </button>
            )}
          </div>
        </div>
    </div>
  );
}

export default App;
