type inputProps = {
  label?: string
  type?: any
  value?: string
  onChange?: any
}

export const Input = ({ label, type, value, onChange }: inputProps) => {


  return (
    <div className="chact-input">
      {!!label && <p className="label">{label}</p>}
      <input
        type={type || 'text'}
        value={value}
        onChange={onChange}
      />
    </div>
  )

}
