import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom"
import { getUser } from "../../graphql/queries";
import { User } from "../../API";

export const ShareProvider = () => {
  const [isShareUser, setIsShareUser] = useState(false);
  let { userId } = useParams();

  useEffect(() => {
    componentDidMount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentDidMount = async () => {
    const data = await API.graphql(
      graphqlOperation(
        getUser, {
          userId: userId
        }
      )
    ) as {
      data: {
        getUser: User
      }
    }

    if(!data.data.getUser) {
      setTimeout(() => {
        window.location.href = "/";
        alert("不正なパスです");
      }, 500);
      return
    } else {
      setIsShareUser(true);
    }
  }

  return(
    <>
      {
        isShareUser ? <Outlet /> : <div />
      }
    </>
  )
}
