import { API, Auth, graphqlOperation } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from './Input'
import blur from '../../assets/blur.webp'
import { usersByEmail } from "../../graphql/queries";
import { User } from "../../API";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await Auth.signIn(email, password);

      navigate("/auth/admin/setting");
    } catch (error) {
      const data = await API.graphql(
        graphqlOperation(
          usersByEmail, {
            email: email
          }
        )
      ) as {
        data: {
          usersByEmail: {
            items: User[]
          }
        }
      }

      if(data.data.usersByEmail.items.length === 0) {
        alert(error)
      }

      navigate(`/share/user/${data.data.usersByEmail.items[0].userId}`);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        marginTop: "5rem",
      }}
    >
      <div className="bg-blob">
        <img alt="" src={blur} />
      </div>

      <h1 className="app-title image-bg">Chact</h1>

      <h1 className="auth-title">Login</h1>
      <div className="auth-container">

        <Input
          label="Email Address"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />

        <Input
          label="Password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />

        <div>
          <a href="/forget-password" className="link-text">Forgot Password?</a>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button
            type="button"
            className="btn theme-button large main-color"
            onClick={handleLogin}
          >
            Login<i className="bi bi-arrow-right-circle ms-2"></i>
          </button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <a href="/sign-up" className="link-text">Admin Sign Up</a>
        </div>
      </div>
    </div>
  )
}
